/* eslint-disable jsx-a11y/anchor-is-valid */
import { Button, Card, Empty, Input, Select, Table, DatePicker, Modal, Spin } from 'antd';
import * as React from "react";
import { DeleteOutlined, SearchOutlined, SyncOutlined } from '@ant-design/icons';
import { handleError } from '../../../utils/error';
import { Snackbar } from '../../../components/js/Snackbar';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import { LogService } from '../../../api/services/log';
import { LOG_ACTIONS, LogFilter } from '../../../api/models/log';
import { RangePickerProps } from 'antd/es/date-picker';
import dayjs from 'dayjs';
import { AuthService } from '../../../api/services/auth';

const { RangePicker } = DatePicker

export const LogList = () => {
    const navigate = useNavigate()
    const unauthorizedCallback = () => {
        navigate('/login', { state: { unauthorized: true }})
    }

    const [loading, setLoading] = React.useState(true)
   
    const [logs, setLogs] = React.useState([])

    const [showSnackbar, setShowSnackbar] = React.useState(false)
    const [snackbarMesssge, setSnackbarMesssge] = React.useState(null)
    const [snackbarType, setSnackbarType] = React.useState('error')
    const [snackbarKey, setSnackbarKey] = React.useState(0)

    const [search, setSearch] = React.useState('')
    const [statusFilter, setStatusFilter] = React.useState('')
    const [actionFilter, setActionFilter] = React.useState('')
    const [dateFromFilter, setDateFromFilter] = React.useState('')
    const [dateToFilter, setDateToFilter] = React.useState('')

    const [currentPageNumber, setCurrentPageNumber] = React.useState(1)
    const [total, setTotal] = React.useState(0)
    const defaultPageSize = 20

    const filter: LogFilter = {
        search: search,
        action: actionFilter,
        status: statusFilter,
        dateFrom: dateFromFilter,
        dateTo: dateToFilter,
        pageSize: defaultPageSize,
        pageNumber: currentPageNumber,
        orderBy: '',
        orderDirection: ''
    }

    const columns = [
        {
            title: 'Date & Time',
            dataIndex: 'createdAt',
            key: 'createdAt',
            render: (data: any) => {
                return data ? moment(data).format('llll') : '---'
            },
            sorter: true
        },
        {
            title: 'Action',
            dataIndex: 'action',
            key: 'action',
            width: '20%'
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            render: (data: any) => {
                return <span className={`${data === 'SUCCESS' ? 'tag-blue' : 'tag-red'} py-1 px-2 my-1 w-full rounded`}>{ data }</span>
            }
        },
        {
            title: 'Details',
            dataIndex: 'details',
            key: 'details'
        },
        {
            title: 'Entity',
            dataIndex: 'entity',
            key: 'entity',
            render: (data: any) => {
                return data || '---'
            }
        },
        {
            title: 'Done By',
            dataIndex: 'doneBy',
            key: 'doneBy'
        }
    ]

    const locale = {
        emptyText: <Empty />,
    };

    React.useEffect(() => {
        filter.search = search
        filter.status = statusFilter
        filter.action = actionFilter
        filter.dateFrom = dateFromFilter
        filter.dateTo = dateToFilter
        filter.pageNumber = 1
        setCurrentPageNumber(1)

        getLogs()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [search, statusFilter, actionFilter, dateToFilter])

    const getLogs = async () => {
        try {
            setLoading(true)

            const response = await LogService.list(filter)
            const data = response?.data
            const logs = data?.map((v: any) => ({
                ...v,
                key: v.id
            }))

            setLogs(logs)
            setTotal(response.total)
        } catch (e) {
            setLogs([])
            setTotal(0)

            const errMsg = handleError(e, unauthorizedCallback)
            triggerSnackbar(errMsg, 'error')
        } finally {
            setLoading(false)
        }  
    }

    const tableChange = async (pagination: any, f: unknown, sorter: any, e: any) => {
        const { current, pageSize } = pagination
        const { column, columnKey, order } = sorter
        const { action } = e

        filter.pageNumber = current || 1
        filter.pageSize = pageSize || defaultPageSize
        setCurrentPageNumber(filter.pageNumber)

        if (column) {
            filter.orderBy = columnKey
            filter.orderDirection = order === 'descend' ? 'DESC' : 'ASC'
        }

        if (action === 'sort' || action === 'search') {
            filter.pageNumber = 1
            setCurrentPageNumber(1)
        }

        await getLogs()
    }

    const triggerSnackbar = (message: string, type: string) => {
        setSnackbarMesssge(message)
        setSnackbarType(type)
        setSnackbarKey(Math.random())
        setShowSnackbar(true)
    }

    const datePickerChange = async (v: any) => {
        setDateFromFilter(v ? v[0].format('YYYY-MM-DD') : '')
        setDateToFilter(v ? v[1].format('YYYY-MM-DD') : '')
    }

    // eslint-disable-next-line arrow-body-style
    const disabledDate: RangePickerProps['disabledDate'] = (current: any) => {
        // Can not select days before today and today
        return current && current > dayjs().endOf('day');
    }

    const showDeleteDatePicker = () => {
        let deleteFrom: string = null
        let deleteTo: string = null

        const instance = Modal.confirm({
            content: <div className="flex flex-col align-center justify-around mb-6">
                       <Spin spinning={loading}>
                            <span className="text-body2 text-neutralBlack font-mulishMedium">Delete Logs</span>
                            <RangePicker className='w-full mt-4 font-mulishMedium' onChange={(v) => {
                                setDateRange(v)
                            }} disabledDate={disabledDate}/>
                       </Spin>
                    </div>,
            cancelButtonProps: {
                className: 'btn-default text-body4 w-1/4 h-8'
            },
            cancelText: 'Cancel',
            okButtonProps: {
                className: `btn-secondary text-body4 w-1/4 h-8`,
                disabled: true
            },
            okText: 'Delete',
            onOk: async() => {
                if (!deleteTo) {
                    return false
                }

                try {
                    setLoading(true)
                    
                    await LogService.delete(deleteFrom, deleteTo)
                    triggerSnackbar('Logs deleted!', 'success')
                    Modal.destroyAll()
                    
                    filter.pageNumber = 1
                    setCurrentPageNumber(1)
                    await getLogs()
                } catch (e) {
                    setLogs([])
                    setTotal(0)
        
                    const errMsg = handleError(e, unauthorizedCallback)
                    triggerSnackbar(errMsg, 'error')
                } finally {
                    setLoading(false)
                }  
            }
        })

        const setDateRange = (dateRange: any) => {
            let buttonDisabled = true
            if (dateRange) {
                deleteFrom = dateRange[0]?.format('YYYY-MM-DD')
                deleteTo = dateRange[1]?.format('YYYY-MM-DD')
                buttonDisabled = false
            }

            instance.update({
                okButtonProps: { 
                    className: `btn-secondary text-body4 w-1/4 h-8`,
                    disabled: buttonDisabled
                }
            })
        }
    }

    return (
        <div className='flex flex-col justify-between mb-8'>
            <Snackbar message={snackbarMesssge} show={showSnackbar} type={snackbarType} key={snackbarKey}/>
            <Card>
                <div className='lg:float-right flex flex-row-reverse mb-2 lg:w-full flex-wrap md:!flex-nowrap'>
                    <div className='flex flex-row justify-between mb-2'>
                        <Button className="btn-default mx-2" icon={<SyncOutlined/>} onClick={() => getLogs()}>Refresh</Button>
                        {AuthService.isAdmin() && <Button className="btn-secondary float-right" icon={<DeleteOutlined />} onClick={async () => {
                           showDeleteDatePicker()
                        }}>Delete</Button>}
                    </div>
                    <div className='flex flex-row-reverse mb-2 flex-wrap md:!flex-nowrap w-full lg:!w-3/4'>
                        <Input
                            className='lg:w-3/4'
                            placeholder="Search by entity..."
                            suffix={<SearchOutlined style={{ color: 'rgba(0,0,0,.45)' }}/>}
                            allowClear
                            onChange={ (e) => {
                                setSearch(e.target.value)
                            }}
                        />
                        <Select placeholder='Filter by Status' style={{ width: 300 }}
                            className='w-full md:!w-2/5 mt-2 md:!mt-0 md:!mx-2'
                            allowClear
                            onChange={ (e) => {
                                setStatusFilter(e)
                            }}
                            options={['SUCCESS', 'FAILED'].map((v) => ({ value: v, label: v}))}
                        />
                        <Select placeholder='Filter by Action' style={{ width: 300 }}
                            className='w-full md:!w-2/5 mt-2 md:!mt-0'
                            allowClear
                            onChange={ (e) => {
                                setActionFilter(e)
                            }}
                            options={LOG_ACTIONS.map((v) => ({ value: v, label: v}))}
                        />
                        <RangePicker className='w-full md:!w-2/5 mt-2 md:!mt-0 md:!mx-2 font-mulishMedium' onChange={datePickerChange} disabledDate={disabledDate}/>
                    </div>
                </div>
                <div className='mt-10'>
                    <Table 
                        onChange={tableChange}
                        loading={loading} 
                        columns={columns} 
                        dataSource={logs} 
                        locale={locale}  
                        scroll={{ y: 10000, x: 1000 }} 
                        pagination={ 
                            { 
                                className: 'mr-2',
                                position: ['bottomRight'],  
                                total,
                                showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
                                showSizeChanger: true,
                                defaultPageSize: defaultPageSize,
                                current: currentPageNumber
                            } 
                        }
                    />
                </div>
            </Card>
        </div>
    )
}