import { HttpMethod } from '../httpMethod'
import { PaginationResponse } from '../models/common'
import { LogFilter } from '../models/log'

export class LogService {
    static API_URL = process.env.REACT_APP_API_BASE_URL || 'http://localhost:1000'
    static BASE_URL = `${this.API_URL}/api/logs`

    static list = async (request: LogFilter) => {
        const filter = {
            search: request.search?.trim() || '',
            action: request.action || '',
            status: request.status || '',
            dateFrom: request.dateFrom || '',
            dateTo: request.dateTo || '',

            pageSize: request.pageSize?.toString() || '',
            pageNumber: request.pageNumber?.toString() || '',
            orderBy: request.orderBy || '',
            orderDirection: request.orderDirection || ''
        }

        const url = `${this.BASE_URL}?${new URLSearchParams(filter).toString()}`
        return await HttpMethod.get(url) as PaginationResponse
    }

    static delete = async (dateFrom: string, dateTo: string) => {
        const url = `${this.BASE_URL}?dateFrom=${dateFrom}&dateTo=${dateTo}`
        return await HttpMethod.delete(url)
    }
}