import { handleError } from '../../utils/error'
import { HttpMethod } from '../httpMethod'
import { CSRFTokenResponse, LoginRequest, LoginResponse, Token, WriteCredentials } from '../models/auth'

export class AuthService {
    static API_URL = process.env.REACT_APP_API_BASE_URL || 'http://localhost:1000'
    static BASE_URL = `${this.API_URL}/api/auth`

    static isAdmin = () => {
        return this.decodeToken()?.role.toUpperCase() === 'ADMIN'
    }

    static isModerator = () => {
        return this.decodeToken()?.role.toUpperCase() === 'MODERATOR'
    }

    static isController = () => {
        return this.decodeToken()?.role.toUpperCase() === 'CONTROLLER'
    }

    static isObserver = () => {
        return this.decodeToken()?.role.toUpperCase() === 'OBSERVER'
    }

    static validateToken = () => {
        const accessToken = localStorage.getItem('ACCESS_TOKEN')
        if (!accessToken) {
          return false
        }
    
        const decodedToken = JSON.parse(atob(accessToken.split(".")[1]));
        return decodedToken.exp * 1000 > Date.now()
    }

    static decodeToken = () => {
        const accessToken = localStorage.getItem('ACCESS_TOKEN')
        if (!accessToken) {
          return null
        }

        return JSON.parse(atob(accessToken.split(".")[1])) as Token;
    }

    static getCSRFToken = async () => {
        try {
            const url = `${this.BASE_URL}/csrf-token`
            const response = await HttpMethod.get(url) as CSRFTokenResponse
            localStorage.setItem('CSRF_TOKEN', response.csrfToken)
        } catch(e) {
            handleError(e)
        }
    }

    static login = async (request: LoginRequest) => {
        const url = `${this.BASE_URL}/login`
        return await HttpMethod.post(url, request) as LoginResponse
    }

    static logout = () => {
        localStorage.clear()
    }

    static setCredentials = async (request: WriteCredentials) => {
        const url = `${this.BASE_URL}`
        return await HttpMethod.post(url, request)
    }
}