import { PaginationFilter } from './common'
import { RoundDetails } from './round'

export const GAME_TYPES = ['COCKFIGHT', 'SPIDER', 'COLOR', 'APPLE']
export interface EventDetails {
    id: number
    arena: string
    numberOfRounds: number
    videoLink: string
    status: string
    startAt: Date
    endAt: Date
    type: string
    rounds: RoundDetails[]
    gameType: string
}

export interface EventFilter extends PaginationFilter {
    gameType?: string
} 

export interface WriteEvent {
    arena: string
    numberOfRounds: number
    videoLink: string
    status?: string
    startAt?: Date
    endAt?: Date
    type?: string
    gameType?: string
}