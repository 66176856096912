import { HttpMethod } from '../httpMethod'
import { PaginationResponse } from '../models/common'
import { RoundDetails, RoundFilter, UpdateRoundStatus, WriteResult, WriteRound } from '../models/round'

export class RoundService {
    static API_URL = process.env.REACT_APP_API_BASE_URL || 'http://localhost:1000'
    static BASE_URL = `${this.API_URL}/api/rounds`

    static list = async (request: RoundFilter) => {
        const filter = {
            search: request.search?.trim() || '',
            eventId: request.eventId || '',
            
            pageSize: request.pageSize?.toString() || '',
            pageNumber: request.pageNumber?.toString() || '',
            orderBy: request.orderBy || '',
            orderDirection: request.orderDirection || ''
        }

        const url = `${this.BASE_URL}?${new URLSearchParams(filter).toString()}`
        return await HttpMethod.get(url) as PaginationResponse
    }

    static get = async (roundID: number) => {
        const url = `${this.BASE_URL}/${roundID}`
        return await HttpMethod.get(url) as RoundDetails
    }

    static getInProgress = async (eventID: number) => {
        const url = `${this.BASE_URL}/in-progress/details?eventId=${eventID}`
        return await HttpMethod.get(url) as RoundDetails
    }

    static getNextWaiting = async (eventID: number) => {
        const url = `${this.BASE_URL}/next-waiting/details?eventId=${eventID}`
        return await HttpMethod.get(url) as RoundDetails
    }

    static create = async (request: WriteRound) => {
        const url = `${this.BASE_URL}`
        return await HttpMethod.post(url, request)
    }

    static createResult = async (request: WriteResult) => {
        const url = `${this.BASE_URL}/${request.roundId}/results`
        return await HttpMethod.post(url, request)
    }

    // static update = async (roundID: number, request: WriteRound) => {
    //     const url = `${this.BASE_URL}/${roundID}`
    //     return await HttpMethod.put(url, request)
    // }

    static updateStatus = async (roundID: number, request: UpdateRoundStatus) => {
        const url = `${this.BASE_URL}/${roundID}/status`
        return await HttpMethod.put(url, request)
    }

    static delete = async (roundID: number) => {
        const url = `${this.BASE_URL}/${roundID}`
        return await HttpMethod.delete(url)
    }
}