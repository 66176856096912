/* eslint-disable jsx-a11y/anchor-is-valid */
import { Button, Card, Empty, Input, Popconfirm, Space, Table } from 'antd';
import * as React from "react";
import { DeleteOutlined, EditOutlined, FileAddOutlined, KeyOutlined, SearchOutlined, SyncOutlined } from '@ant-design/icons';
import { handleError } from '../../../utils/error';
import { Snackbar } from '../../../components/js/Snackbar';
import { useNavigate } from 'react-router-dom';
import { showFormModal } from '../../../components/Modals';
import { UserForm } from './UserForm';
import { UserService } from '../../../api/services/user';
import { UserDetails, UserFilter } from '../../../api/models/user';
import { CredentialsForm } from './CredentialsForm';

export const UserList = () => {
    const navigate = useNavigate()
    const unauthorizedCallback = () => {
        navigate('/login', { state: { unauthorized: true }})
    }

    const [loading, setLoading] = React.useState(true)
    const [users, setUsers] = React.useState([])
    
    const [showSnackbar, setShowSnackbar] = React.useState(false)
    const [snackbarMesssge, setSnackbarMesssge] = React.useState(null)
    const [snackbarType, setSnackbarType] = React.useState('error')
    const [snackbarKey, setSnackbarKey] = React.useState(0)

    const [search, setSearch] = React.useState('')
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [roleFilter, setRoleFilter] = React.useState('')
    const [total, setTotal] = React.useState(0)
    const [currentPageNumber, setCurrentPageNumber] = React.useState(1)
    const defaultPageSize = 20
    
    const filter: UserFilter = {
        role: roleFilter,
        pageSize: defaultPageSize,
        pageNumber: currentPageNumber,
        orderBy: '',
        orderDirection: ''
    } 

    const columns = [
        {
            title: 'Email',
            dataIndex: 'email',
            key: 'email',
            sorter: true
        },
        {
            title: 'Role',
            dataIndex: 'role',
            key: 'role',
            sorter: true
        },
        {
            title: 'Username',
            dataIndex: 'username',
            key: 'username',
            render: (data: string) => {
                return data || '---'
            }
        },
        {
            title: 'Action',
            dataIndex: 'action',
            key: 'action'
        }
    ]

    const locale = {
        emptyText: <Empty />,
    };

    React.useEffect(() => {
        filter.search = search
        filter.role = roleFilter
        filter.pageNumber = 1
        getUsers()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [search, roleFilter])

    const getUsers = async () => {
        try {
            setLoading(true)

            const response = await UserService.list(filter)
            const data = response?.data as UserDetails[]
            const users = data?.map((v: UserDetails) => ({
                ...v,
                key: v.id,
                action: <Space>
                    <Button className="btn-primary" icon={<EditOutlined/>} size='small' onClick={() => showForm('Edit User', v)} title='Edit'/>
                    <Button className="btn-default" icon={<KeyOutlined/>} size='small' onClick={() => showCredsForm(v)} title='Set Credentials'/>
                    <Popconfirm
                        title={<span className='font-mulishBold text-body4'>Delete User</span>}
                        description={<span className='font-mulishMedium text-body4'>Are you sure to delete <span className='font-mulishBold'>{v.username || v.email}</span>?</span>}
                        okText="Yes"
                        cancelText="No"
                        okButtonProps={{ className: '!bg-primary font-mulishMedium text-body6'}}
                        cancelButtonProps={{ className: '!font-mulishMedium text-body6'}}
                        onConfirm={ async () => { await deleteUser(v.id); getUsers() }}
                    >
                        <Button className="btn-secondary" icon={<DeleteOutlined/>} size='small' title='Delete'></Button>
                    </Popconfirm>
                </Space>
            }))

            setUsers(users)
            setTotal(response.total)
        } catch (e) {
            setUsers([])
            setTotal(0)

            const errMsg = handleError(e, unauthorizedCallback)
            triggerSnackbar(errMsg, 'error')
        } finally {
            setLoading(false)
        }  
    }

    const deleteUser = async (id: number) => {
        try {
            await UserService.delete(id)
            triggerSnackbar('User deleted!', 'success')
        } catch (e) {
            const errMsg = handleError(e, unauthorizedCallback)
            triggerSnackbar(errMsg, 'error')
        }
    }

    const showForm = (formTitle?: string, formValues?: object) => {
        showFormModal(
            <UserForm formTitle={formTitle || 'Add User'} initialValues={formValues} onSuccessCallback={() => {
                triggerSnackbar('User saved!', 'success')

                // reset filters if add user
                const { id } = formValues as any
                if (!id) {
                    filter.search = ''
                    filter.role = ''
                    filter.pageNumber = 1
                    filter.pageSize = defaultPageSize
                    filter.orderBy = ''
                    filter.orderDirection = ''
                }

                getUsers()
            }}
            unauthorizedCallback={unauthorizedCallback}
            />
        )
    }

    const showCredsForm = async (user: UserDetails) => {
        showFormModal(
            <CredentialsForm initialValues={user} onSuccessCallback={() => {
                triggerSnackbar('Credentials saved!', 'success')
                getUsers()
            }}
            unauthorizedCallback={unauthorizedCallback}
            />
        )
    }

    const tableChange = async (pagination: any, f: unknown, sorter: any, e: any) => {
        const { current, pageSize } = pagination
        const { column, columnKey, order } = sorter
        const { action } = e

        filter.pageNumber = current || 1
        filter.pageSize = pageSize || defaultPageSize
        setCurrentPageNumber(filter.pageNumber)

        if (column) {
            filter.orderBy = columnKey
            filter.orderDirection = order === 'descend' ? 'DESC' : 'ASC'
        }

        if (action === 'sort' || action === 'search') {
            filter.pageNumber = 1
            setCurrentPageNumber(1)
        }

        await getUsers()
    }

    const triggerSnackbar = (message: string, type: string) => {
        setSnackbarMesssge(message)
        setSnackbarType(type)
        setSnackbarKey(Math.random())
        setShowSnackbar(true)
    }

    return (
        <div className='flex flex-col justify-between mb-8'>
            <Snackbar message={snackbarMesssge} show={showSnackbar} type={snackbarType} key={snackbarKey}/>
            <Card className='mt-2'>
                <div className='lg:float-right flex flex-row-reverse mb-2 lg:w-1/2 flex-wrap'>
                    <div className='flex flex-row justify-between mb-2'>
                        <Button className="btn-primary mx-2 float-right" icon={<FileAddOutlined />} onClick={() => showForm(null, {})}>Add User</Button>
                        <Button className="btn-default" icon={<SyncOutlined/>} onClick={() => getUsers()}>Refresh</Button>
                    </div>
                    <Input
                        className='lg:w-1/2 lg:mb-2'
                        placeholder="Search by email..."
                        suffix={<SearchOutlined style={{ color: 'rgba(0,0,0,.45)' }}/>}
                        allowClear
                        onChange={ (e) => {
                            setSearch(e.target.value)
                        }}
                    />
                </div>
                <div className='mt-10'>
                    <Table 
                        className='w-screen'
                        onChange={tableChange}
                        loading={loading} 
                        columns={columns} 
                        dataSource={users} 
                        locale={locale}  
                        scroll={{ y: 10000, x: 500 }} 
                        pagination={ 
                            { 
                                className: 'mr-2',
                                position: ['bottomRight'],  
                                total,
                                showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
                                showSizeChanger: true,
                                defaultPageSize: defaultPageSize,
                                current: currentPageNumber
                            } 
                        }
                    />
                </div>
            </Card>
        </div>
    )
}