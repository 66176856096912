import { PaginationFilter } from './common'

export const userRoles = [
    'ADMIN',
    'MODERATOR',
    'CONTROLLER',
    'OBSERVER'
]

export interface UserDetails {
    id: number
    email: string
    role: string
    username: string
}

export interface UserFilter extends PaginationFilter {
   role: string
}

export interface WriteUser {
    email?: string
    role: string
}