import * as React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Button, Form, Input, Spin } from 'antd';
import { LockOutlined, UserOutlined } from '@ant-design/icons';
import { Snackbar } from '../../components/js/Snackbar';
import { AuthService } from '../../api/services/auth';
import { handleError } from '../../utils/error';

export const Login = () => {    
    const location = useLocation();
    const navigate = useNavigate();

    const [loading, setLoading] = React.useState(false)

    const [showSnackbar, setShowSnackbar] = React.useState(false)
    const [snackbarMesssge, setSnackbarMesssge] = React.useState(null)
    const [snackbarType, setSnackbarType] = React.useState('error')
    const [snackbarKey, setSnackbarKey] = React.useState(Math.random())

    React.useEffect(() => {
        const state = location.state;
        if (state?.unauthorized) {
            triggerSnackbar('User session expired!', 'error')
            navigate(location.pathname, { replace: true });
        }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const login = async (values: any) => {
        const { username, password } = values  
        if (!username || !password) {
            triggerSnackbar('Invalid username or password!', 'error')
            return
        }

        try {   
            setLoading(true)      
            await AuthService.getCSRFToken()
            const response = await AuthService.login({ username, password })
            localStorage.setItem('ACCESS_TOKEN', response.accessToken)

            window.location.reload()
        } catch(e) {
            const errMsg = handleError(e)
            triggerSnackbar(errMsg, 'error')
        } finally {
            setLoading(false)
        }
    }

    const triggerSnackbar = (message: string, type: string) => {
        setSnackbarMesssge(message)
        setSnackbarType(type)
        setSnackbarKey(Math.random())
        setShowSnackbar(true)
    }
    
    return (
        <div className="h-screen w-full flex justify-center items-center">
                <Snackbar message={snackbarMesssge} show={showSnackbar} type={snackbarType} key={snackbarKey}/>
                <div className='lg:w-1/5 text-center'>
                    <Spin spinning={loading} className='w-full'>
                        <span className='text-header3 font-mulishBold text-neutralBlack-75'>Log In</span>
                        <Form onFinish={login} className='mt-4'>
                            <Form.Item name="username">
                                <Input prefix={<UserOutlined className="site-form-item-icon" />} placeholder="Username" />
                            </Form.Item>
                            <Form.Item name="password">
                                <Input.Password
                                    prefix={<LockOutlined className="site-form-item-icon" />}
                                    placeholder="Password"
                                />
                            </Form.Item>
                            <Form.Item>
                                <Button className="btn-primary w-full" htmlType="submit">
                                    Login
                                </Button>
                            </Form.Item>
                        </Form>
                    </Spin>
                </div>
        </div>
    )
}