import { CloseOutlined, LoadingOutlined, SaveOutlined, UndoOutlined } from '@ant-design/icons';
import { Form, Input, Button, Space, Modal, Select } from 'antd'
import * as React from 'react'
import { handleError } from '../../../utils/error';
import { Snackbar } from '../../../components/js/Snackbar';
import { EventService } from '../../../api/services/event';
import { GAME_TYPES } from '../../../api/models/event';

export const EventForm = ({ formTitle, initialValues, onSuccessCallback, unauthorizedCallback } : 
    { formTitle: string, initialValues?: any, onSuccessCallback: Function, unauthorizedCallback: Function}) => {

    const formValues = initialValues
    const [form] = Form.useForm()

    const [loading, setLoading] = React.useState(false)
    const [showSnackbar, setShowSnackbar] = React.useState(false)
    const [snackbarMesssge, setSnackbarMesssge] = React.useState(null)
    const [snackbarType] = React.useState('error')
    const [snackbarKey, setSnackbarKey] = React.useState(0)

    const submit = async (values: any) => {
        try {
            setLoading(true)

            const { arena, numberOfRounds, videoLink, gameType } = values

            if (formValues.id) {
                await EventService.update(formValues.id, {
                    arena,
                    numberOfRounds,
                    videoLink,
                    gameType
                })
            } else {
                await EventService.create({
                    arena,
                    numberOfRounds,
                    videoLink,
                    status: 'WAITING',
                    gameType
                }) as any
            }
            
            onSuccessCallback()  
            Modal.destroyAll()          
        } catch (e) {
            const errMsg = handleError(e, unauthorizedCallback)
            triggerSnackbar(errMsg)
        } finally {
            setLoading(false)
        }  
    };

    const triggerSnackbar = (message: string) => {
        setSnackbarMesssge(message)
        setSnackbarKey(Math.random())
        setShowSnackbar(true)
    }

    return (
        <div className='flex flex-col justify-between '>
            <Snackbar message={snackbarMesssge} show={showSnackbar} type={snackbarType} key={snackbarKey}/>
            
            <span className='text-header4 font-mulishBold text-neutralBlack text-center'>
                {formTitle}
            </span>
            <div className='mt-4 form-container'>
                <Form
                    form={form}
                    labelCol={{ span: 4 }}
                    wrapperCol={{ md: { offset: 1 } }}
                    scrollToFirstError
                    onFinish={submit}
                    initialValues={formValues}
                    className='app-form'
                >
                
                <Form.Item label='Arena' name='arena'
                    rules={[{
                        required: true,
                        message: 'This field is required.',
                    }]} 
                    hasFeedback
                >
                    <Input/> 
                </Form.Item>
                <Form.Item label={<span><span className='text-error'>*</span> Rounds</span>} name='numberOfRounds'
                    rules={[{
                            type: 'number',
                            min: 1,
                            message: 'Should be greater than 0.',
                            transform(value) {
                                return Number(value)
                            }
                    }]} 
                    hasFeedback
                >
                    <Input type='number' style={{width: 125}}/>
                </Form.Item>
                <Form.Item label='Video Link' name='videoLink'
                     rules={[{
                        required: true,
                        message: 'This field is required.',
                    }]} 
                    hasFeedback
                >
                    <Input.TextArea autoSize={{ minRows: 6, maxRows: 6 }}/>
                </Form.Item>
                <Form.Item label='Game Type' name='gameType'
                    rules={[{
                        required: true,
                        message: 'This field is required.',
                    }]} 
                    hasFeedback
                >
                    <Select
                        disabled={initialValues.startAt}
                        allowClear
                        options={GAME_TYPES.map((v: string) => ({ value: v, label: v}))}
                    />
                </Form.Item>
                {
                    !loading ?
                        <Form.Item className='float-right lg:mr-3 md:mr-3'>
                            <Space>
                                <Button className='btn-primary' htmlType="submit" icon={<SaveOutlined/>}>Save</Button>
                                <Button className='btn-secondary' htmlType="reset" icon={<UndoOutlined/>}>Reset</Button>
                                <Button className='btn-default' onClick={() => Modal.destroyAll() } icon={<CloseOutlined/>}>Close</Button>
                            </Space>
                        </Form.Item>
                    :
                    <Form.Item className='float-right lg:mr-3 md:mr-3'>
                        <span className='text-primary text-body4 font-mulishMedium'><LoadingOutlined/></span>
                    </Form.Item>
                }

                </Form>
            </div>
        </div>
    )
}