import { CloseOutlined, LoadingOutlined, SaveOutlined, UndoOutlined } from '@ant-design/icons';
import { Form, Input, Button, Space, Modal } from 'antd'
import * as React from 'react'
import { handleError } from '../../../utils/error';
import { Snackbar } from '../../../components/js/Snackbar';
import { RoundService } from '../../../api/services/round';
import { UserService } from '../../../api/services/user';

export const RoundForm = ({ eventID, onSuccessCallback, unauthorizedCallback } : 
    { eventID: number, onSuccessCallback: Function, unauthorizedCallback: Function}) => {

    const user = UserService.user
    const [form] = Form.useForm()

    const [loading, setLoading] = React.useState(false)
    const [showSnackbar, setShowSnackbar] = React.useState(false)
    const [snackbarMesssge, setSnackbarMesssge] = React.useState(null)
    const [snackbarType] = React.useState('error')
    const [snackbarKey, setSnackbarKey] = React.useState(0)

    const submit = async (values: any) => {
        try {
            setLoading(true)

            await RoundService.create({
                eventId: eventID,
                fightNumber:  Number(values.fightNumber),
                status: 'WAITING',
                updatedBy: user.id
            })
            
            onSuccessCallback()  
            Modal.destroyAll()          
        } catch (e) {
            const errMsg = handleError(e, unauthorizedCallback)
            const error = JSON.parse(e.message)
            if (error?.error_code === 'PROVIDER_FAILED') {
                onSuccessCallback(errMsg)
                Modal.destroyAll()         
            } else {
                triggerSnackbar(errMsg)
            }
        } finally {
            setLoading(false)
        }  
    };

    const triggerSnackbar = (message: string) => {
        setSnackbarMesssge(message)
        setSnackbarKey(Math.random())
        setShowSnackbar(true)
    }

    return (
        <div className='flex flex-col justify-between '>
            <Snackbar message={snackbarMesssge} show={showSnackbar} type={snackbarType} key={snackbarKey}/>
            
            <span className='text-header4 font-mulishBold text-neutralBlack text-center'>
                Set Round
            </span>
            <div className='mt-4 form-container'>
                <Form
                    form={form}
                    labelCol={{ span: 6 }}
                    wrapperCol={{ md: { offset: 1 } }}
                    scrollToFirstError
                    onFinish={submit}
                    className='app-form'
                >
                
                <Form.Item label={<span><span className='text-error'>*</span> Round Number</span>} name='fightNumber'
                    rules={[{
                            type: 'number',
                            min: 1,
                            message: 'Should be greater than 0.',
                            transform(value) {
                                return Number(value)
                            }
                    }]} 
                    hasFeedback
                >
                    <Input type='number'/>
                </Form.Item>
                {
                    !loading ?
                        <Form.Item className='float-right lg:mr-3 md:mr-3'>
                            <Space>
                                <Button className='btn-primary' htmlType="submit" icon={<SaveOutlined/>}>Save</Button>
                                <Button className='btn-secondary' htmlType="reset" icon={<UndoOutlined/>}>Reset</Button>
                                <Button className='btn-default' onClick={() => Modal.destroyAll() } icon={<CloseOutlined/>}>Close</Button>
                            </Space>
                        </Form.Item>
                    :
                    <Form.Item className='float-right lg:mr-3 md:mr-3'>
                        <span className='text-primary text-body4 font-mulishMedium'><LoadingOutlined/></span>
                    </Form.Item>
                }

                </Form>
            </div>
        </div>
    )
}