export const handleError = (e: Error, callback?: Function) => {
    console.log('error', e)
    let errMsg = 'Something went wrong. Please contact administrator'
    try {
        const error = JSON.parse(e.message)
        if (error?.error_code === 'UNAUTHORIZED') {
            callback()
            return
        } else if (error?.error_code === 'PROVIDER_FAILED') {
            return 'Unable to sync data to provider!'
        }

        errMsg = error.metadata?.message || errMsg
    } catch {}

    return errMsg
}