import { PaginationFilter } from './common'

export const LOG_ACTIONS = ['EVENT.CREATE', 'EVENT.UPDATE', 'ROUND.CREATE', 'ROUND.UPDATE',
'ROUND.STATUS.UPDATE', 'RESULT.CREATE', 'PROVIDER.SYNC', 'PROVIDER.SYNC.DEMO', 'PROVIDER.SYNC.PROD',
'USER.CREATE', 'USER.UPDATE', 'USER.DELETE']

export interface LogFilter extends PaginationFilter {
    action?: string
    status?: string
    dateFrom?: string
    dateTo?: string
} 