import { BellOutlined, CheckCircleOutlined, CloseOutlined, StopOutlined, WarningOutlined } from '@ant-design/icons';
import * as React from 'react'
import styled, { keyframes } from 'styled-components';

export const Snackbar = ({ show, message, type = '', timeout = 3000 }) => {
    const [shouldShow, setShouldShow] = React.useState(show)

    let TIME = (timeout - 500) / 1000 + "s";
    let POSITION = { top: true, left: false, center: false };
    let TIMER;
    let COLORS = {};

    function handleTimeout() {
      TIMER = setTimeout(() => {
        setShouldShow(false)
        POSITION = null;
      }, timeout);
    }
  
    function handleClose() {
      clearTimeout(TIMER);
      setShouldShow(false)
    }
  
    function setIcon() {
      switch (type) {
        case "success": {
          return <CheckCircleOutlined/>;
        }
  
        case "warning": {
          return <WarningOutlined/>;
        }
  
        case "error": {
          return <StopOutlined/>;
        }
  
        default: {
          return <BellOutlined/>;
        }
      }
    }
  
    if (shouldShow) {
      handleTimeout();
    }
  
    switch (type) {
      case "success": {
        COLORS = {
          primary: "hsl(147, 57%, 60%)",
          secondary: "hsl(147, 57%, 55%)",
        };
        break;
      }
  
      case "warning": {
        COLORS = {
          primary: "hsl(47, 100%, 50%)",
          secondary: "hsl(47, 100%, 45%)",
        };
        break;
      }
  
      case "error": {
        COLORS = {
          primary: "hsl(359, 100%, 70%)",
          secondary: "hsl(359, 100%, 65%)",
        };
        break;
      }
  
      default: {
        COLORS = {
          primary: "hsl(200, 100%, 65%)",
          secondary: "hsl(200, 100%, 60%)",
        };
        break;
      }
    }
  
    return (
      shouldShow && (
        <Bar id="snackbar" timeout={TIME} position={POSITION} color={COLORS}>
          <Left>
            {setIcon()}
            <Text>{message}</Text>
          </Left>
  
          <Button onClick={handleClose} color={COLORS}><CloseOutlined/></Button>
        </Bar>
      )
    );
  };
  
  const fadein = (pos) => keyframes`
      from {
        ${pos.top ? "top: 0" : "bottom: 0"};
        opacity: 0;
      }
      to {
        ${pos.top ? "top: 1rem" : "bottom: 1rem"};
        opacity: 1;
      }
  `;
  
  const fadeout = (pos) => keyframes`
      from {
        ${pos.top ? "top: 1rem" : "bottom: 1rem"};
        opacity: 1;
      }
      to {
        ${pos.top ? "top: 0" : "bottom: 0"};
        opacity: 0;
      }
  `;
  
  const Bar = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: fixed;
    z-index: 9999;
    ${(props) => (props.position.top ? "top: 1rem" : "bottom: 1rem")};
    ${(props) => (props.position.center ? "left: 50%" : null)};
    ${(props) => (props.position.left ? "left: 1rem" : "right: 1rem")};
    transform: ${(props) => (props.position.center ? "translateX(-50%)" : null)};
    width: max-content;
    min-width: min-content;
    max-width: calc(100% - 2rem);
    height: auto;
    padding: 0.625rem 1rem;
    border-radius: 0.75rem;
    background-color: ${(props) => props.color.primary};
    border: transparent;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
    transition: all 0.5s;
    animation: ${(props) => fadein(props.position)} 0.5s,
      ${(props) => fadeout(props.position)} 0.5s ${(props) => props.timeout};
  `;
  
  const Left = styled.div`
    flex: 8;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    color: white;
  `;
  
  const Text = styled.p`
    line-height: 1.6;
    font-size: 12px;
    margin-left: 0.875rem;
    width: 100%;
    white-space: pre-wrap;
    word-wrap: break-word;
    font-family: 'MulishMedium'
  `;
  
  const Button = styled.button`
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 0.875rem;
    padding: 0;
    height: 1.7rem;
    width: 1.7rem;
    text-align: center;
    border: none;
    border-radius: 50%;
    background-color: transparent;
    color: #fff;
    cursor: pointer;
  
    &:hover {
      background-color: ${(props) => props.color.secondary};
    }
`;