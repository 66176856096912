const headers = () => {
    const csrfToken  = localStorage.getItem('CSRF_TOKEN')
    const accessToken  = localStorage.getItem('ACCESS_TOKEN')

    return {
        'Content-Type': 'application/json',
        'X-CSRF-Token': csrfToken,
        'Authorization': `Bearer ${accessToken}`
    }       
}

export interface APIRequest {
    credentials: string
    method: string
    headers: object
    body?: string
}

export class HttpMethod {
    
    static apiRequest(method: string, url: string, request: unknown = null) {
        return new Promise((resolve, reject) => {
            const init: APIRequest = {
                credentials: 'include',
                method,
                headers: headers(),
            }

            if (request) {
                init.body = JSON.stringify(request)
            }

            fetch(url, init as RequestInit)
            .then(async res => {
                if (!res.ok) {
                    throw new Error(await res.text())
                }

                const contentType = res.headers.get('Content-Type')
                return contentType && contentType.includes('application/json') ? res.json() : null
            })
            .then(resolve)
            .catch(reject)
        })
    }
   
    static async get(url: string) {
        return await this.apiRequest('GET', url)
    }

    static async post(url: string, body: unknown) {
        return await this.apiRequest('POST', url, body)
    }

    static async put(url: string, body: unknown) {
        return await this.apiRequest('PUT', url, body)
    }

    static async delete(url: string) {
        return await this.apiRequest('DELETE', url)
    }
}