import * as React from "react";
import { ReactNode } from "react";
import { Navigate, Route, Routes } from 'react-router-dom';
import { EventList } from './pages/authenticated/dashboard/EventList';
import { RoundList } from './pages/authenticated/round/RoundList';
import { AdminGuard, AuthGuard } from './guards/auth';
import { ItemType } from 'antd/es/menu/hooks/useItems';
import { UserList } from './pages/authenticated/user/UserList';
import { LogList } from './pages/authenticated/logs/LogList';

type MenuItem = ItemType & {
  children?: MenuItem[];
  component?: ReactNode;
};

export const routes: MenuItem[] = [
  {
      component: <EventList/>,
      key: '/dashboard',
      label: 'Dashboard'
  },
  {
    component: <UserList/>,
    key: '/users',
    label: 'Users'
  },
  {
    component: <LogList/>,
    key: '/logs',
    label: 'Logs'
}
]

export const RouteContent: React.FC = () => {
  const result: Array<ReactNode> = [];
  
  const dfs = (routes: MenuItem[]) => {
    routes.forEach((item: MenuItem) => {
      if (item.key === '/users' || item.key === '/logs') {
        result.push(<Route element={<AdminGuard/>} key={item.key}>
          <Route
            element={item.component}
            key={item.key}
            path={item.key?.toString()}
          />
          </Route>
        )

        return
      }

      result.push(
        <Route
          element={item.component}
          key={item.key}
          path={item.key?.toString()}
        />
      );
      if (item.children) {
        dfs(item.children);
      }
    });
  };

  result.push(<Route
    element={<RoundList/>}
    key={'event'}
    path={'/dashboard/events/:eventID'}
  />,
  <Route
    element={<Navigate to="/dashboard"/>}
    key={'404'}
    path='*'
  />)

  dfs(routes);

  return <Routes>
      <Route element={<AuthGuard/>}>
        {result}
      </Route>
    </Routes>
};