import * as React from "react";
import { Layout } from 'antd';
import { Navigate, Route, Routes } from 'react-router-dom';
import { Login } from './pages/unauthenticated/Login';
import { Index } from './pages/authenticated/Index';
import { AuthService } from './api/services/auth';
import { TokenVerify, AuthGuard } from './guards/auth';

const App = () => {
  // fetch csrf token every render
  AuthService.getCSRFToken()

  return (
    <Layout style={{ minHeight: '100vh' }}>
      <Routes>
        <Route element={<TokenVerify/>}>
          <Route
            element={<Login/>}
            key={'login'}
            path='/login'
          />
        </Route>
        <Route element={<AuthGuard/>}>
          <Route
            element={<Index/>}
            key={'index'}
            path='/*'
          />
        </Route>
        <Route
          path="/"
          element={<Navigate to="/dashboard" />}
        />
      </Routes>
    </Layout>
  );
};

export default App;