import { HttpMethod } from '../httpMethod'
import { EventDetails, EventFilter, WriteEvent } from '../models/event'
import { PaginationResponse } from '../models/common'

export class EventService {
    static API_URL = process.env.REACT_APP_API_BASE_URL || 'http://localhost:1000'
    static BASE_URL = `${this.API_URL}/api/events`

    static list = async (request: EventFilter) => {
        const filter = {
            search: request.search?.trim() || '',
            gameType: request.gameType || '',

            pageSize: request.pageSize?.toString() || '',
            pageNumber: request.pageNumber?.toString() || '',
            orderBy: request.orderBy || '',
            orderDirection: request.orderDirection || ''
        }

        const url = `${this.BASE_URL}?${new URLSearchParams(filter).toString()}`
        return await HttpMethod.get(url) as PaginationResponse
    }


    static get = async (eventID: number) => {
        const url = `${this.BASE_URL}/${eventID}`
        return await HttpMethod.get(url) as EventDetails
    }

    static getInProgress = async (eventID: number) => {
        const url = `${this.BASE_URL}/${eventID}/in-progress/details`
        return await HttpMethod.get(url) as EventDetails
    }

    static create = async (request: WriteEvent) => {
        const url = `${this.BASE_URL}`
        return await HttpMethod.post(url, request)
    }

    static update = async (eventID: number, request: WriteEvent) => {
        const url = `${this.BASE_URL}/${eventID}`
        return await HttpMethod.put(url, request)
    }

    static delete = async (eventID: number) => {
        const url = `${this.BASE_URL}/${eventID}`
        return await HttpMethod.delete(url)
    }
}