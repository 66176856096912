import { CloseOutlined, LoadingOutlined, SaveOutlined, UndoOutlined } from '@ant-design/icons';
import { Form, Input, Button, Space, Modal } from 'antd'
import * as React from 'react'
import { handleError } from '../../../utils/error';
import { Snackbar } from '../../../components/js/Snackbar';
import { UserDetails } from '../../../api/models/user';
import { WriteCredentials } from '../../../api/models/auth';
import { AuthService } from '../../../api/services/auth';

export const CredentialsForm = ({ initialValues, onSuccessCallback, unauthorizedCallback } : 
    { initialValues?: object, onSuccessCallback: Function, unauthorizedCallback: Function }) => {

    const formValues = initialValues as UserDetails

    const [form] = Form.useForm()

    const [loading, setLoading] = React.useState(false)
    const [showSnackbar, setShowSnackbar] = React.useState(false)
    const [snackbarMesssge, setSnackbarMesssge] = React.useState(null)
    const [snackbarType] = React.useState('error')
    const [snackbarKey, setSnackbarKey] = React.useState(Math.random())

    const submit = async (values: any) => {
        const request = values as WriteCredentials
        request.userId = formValues.id

        try {
            setLoading(true)

            await AuthService.setCredentials(request)

            onSuccessCallback()  
            Modal.destroyAll()          
        } catch (e) {
            const errMsg = handleError(e, unauthorizedCallback)
            triggerSnackbar(errMsg)
        } finally {
            setLoading(false)
        }  
    };

    const triggerSnackbar = (message: string) => {
        setSnackbarMesssge(message)
        setSnackbarKey(Math.random())
        setShowSnackbar(true)
    }

    return (
        <div className='flex flex-col justify-between '>
            <Snackbar message={snackbarMesssge} show={showSnackbar} type={snackbarType} key={snackbarKey}/>
            
            <span className='text-header4 font-mulishBold text-neutralBlack text-center'>
                Set Credentials
            </span>
            <div className='mt-4 form-container'>
                <Form
                    form={form}
                    labelCol={{ span: 4 }}
                    wrapperCol={{ md: { offset: 1 } }}
                    scrollToFirstError
                    onFinish={submit}
                    initialValues={formValues}
                    className='app-form'
                >
                
                <Form.Item label='Email' name='email'>
                        <Input disabled/> 
                </Form.Item>
                <Form.Item label='Username' name='username'
                    rules={[{
                        required: true,
                        message: 'This field is required.',
                    }]} 
                    hasFeedback
                >
                    <Input/> 
                </Form.Item>
                <Form.Item label='Password' name='password'
                    rules={[{
                        required: true,
                        message: 'This field is required.',
                    }]} 
                    hasFeedback
                >
                    <Input/> 
                </Form.Item>
                {
                    !loading ?
                        <Form.Item className='float-right lg:mr-3 md:mr-3'>
                            <Space>
                                <Button className='btn-primary' htmlType="submit" icon={<SaveOutlined/>}>Save</Button>
                                <Button className='btn-secondary' htmlType="reset" icon={<UndoOutlined/>}>Reset</Button>
                                <Button className='btn-default' onClick={() => Modal.destroyAll() } icon={<CloseOutlined/>}>Close</Button>
                            </Space>
                        </Form.Item>
                    :
                    <Form.Item className='float-right lg:mr-3 md:mr-3'>
                        <span className='text-primary text-body4 font-mulishMedium'><LoadingOutlined/></span>
                    </Form.Item>
                }
                </Form>
            </div>
        </div>
    )
}