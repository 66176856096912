import { useState } from 'react';
import { routes } from '../routes';
import { Dropdown, Menu, MenuProps } from 'antd';
import { useLocation, useNavigate } from 'react-router-dom';
import React from 'react';
import { AuthService } from '../api/services/auth';
import { MenuOutlined } from '@ant-design/icons';

const accountIcon = require('../assets/icons/account_icon.png')

export const Header = () => {
    const navigate = useNavigate();
    const location = useLocation(); 
    const [current, setCurrent] = useState('');
    const [menuItems, setMenuItems] = useState([])
    
    React.useEffect(() => {
      const path = (location.pathname.includes('/dashboard')) ? '/dashboard' : location.pathname
      setCurrent(path)

      const items = routes
      if (!AuthService.isAdmin()) {
        const userMenuIdx = routes.findIndex((v) => v.key.toString() === '/users')
        items.splice(userMenuIdx, 1)

        const logsMenuIdx = routes.findIndex((v) => v.key.toString() === '/logs')
        items.splice(logsMenuIdx, 1)
      }

      setMenuItems(items)

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location])

    const onClick: MenuProps['onClick'] = (e) => {
        setCurrent(e.key);
        navigate(e.key);
    };

    const action = (actionType: string) => {
        switch (actionType) {
          case 'LOG_OUT':
            AuthService.logout()
            AuthService.getCSRFToken()
            navigate('/login')
            break
        }
    }

    const items: MenuProps['items'] = ['Log Out'].map((v) => ({
        label: v,
        key: v.replace(' ', '_').toUpperCase(),
        onClick: () => { setTimeout(() => action(v.replace(' ', '_').toUpperCase()), 300)}
    }))

    return (
        <div className='flex flex-row justify-between pr-8 pl-4 py-6 !bg-neutralBlack'>
            <Menu onClick={onClick} selectedKeys={[current]} mode="horizontal" items={menuItems} className='w-1/3' overflowedIndicator={<MenuOutlined/>}/>
            <div className='flex justify-between items-center'>
                <div className='flex flex-col justify-center'>
                  <img src={accountIcon} alt='' className='h-8 header-account' />
                </div>
                &nbsp;&nbsp;&nbsp;
                <Dropdown menu={{ items }} trigger={['click']}>
                  <span className='text-header5 text-neutralWhite font-mulishBold self-center cursor-pointer'>
                    {AuthService.decodeToken()?.username}
                  </span>
                </Dropdown>
              </div>
        </div>
    );
}