import { Modal } from "antd";
import * as React from "react";

export const showFormModal = (content: React.ReactElement) => {
    return (
        Modal.confirm({
            content,
            footer: null,
            className: 'form-modal',
        })
    )
}

export const showConfirmModal = (message: string, onOkCallback: Function, okBtnClass?: string) => {
    return (
        Modal.confirm({
            content: <div className="flex flex-col align-center justify-around mb-6">
                        <span className="text-body2 text-neutralBlack font-mulishMedium">{message}</span>
                    </div>,
            cancelButtonProps: {
                className: 'btn-default text-body4 w-1/4 h-8'
            },
            cancelText: 'No',
            okButtonProps: {
                className: `${okBtnClass || 'btn-primary'} text-body4 w-1/4 h-8`
            },
            okText: 'Yes',
            onOk() {
              onOkCallback()
            }
        })
    )
}