import { handleError } from '../../utils/error'
import { HttpMethod } from '../httpMethod'
import { PaginationResponse } from '../models/common'
import { UserDetails, UserFilter, WriteUser } from '../models/user'
import { AuthService } from './auth'

export class UserService {
    static API_URL = process.env.REACT_APP_API_BASE_URL || 'http://localhost:1000'
    static BASE_URL = `${this.API_URL}/api/users`

    static user: UserDetails = null

    static get = async () => {
        try {
            if (!AuthService.validateToken()) {
                return
            }

            const token = AuthService.decodeToken()
            const url = `${this.BASE_URL}/${token.userId}`
            const response = await HttpMethod.get(url) as UserDetails
            this.user = response
        } catch(e) {
            handleError(e)
        }
    }

    static getByID = async (userID: number) => {
        const url = `${this.BASE_URL}/${userID}`
        return await HttpMethod.get(url) as UserDetails
    }


    static list = async (request: UserFilter) => {
        const filter = {
            search: request.search?.trim() || '',
            role: request.role || '',

            pageSize: request.pageSize?.toString() || '',
            pageNumber: request.pageNumber?.toString() || '',
            orderBy: request.orderBy || '',
            orderDirection: request.orderDirection || ''
        }

        const url = `${this.BASE_URL}?${new URLSearchParams(filter).toString()}`
        return await HttpMethod.get(url) as PaginationResponse
    }

    static create = async (request: WriteUser) => {
        const url = `${this.BASE_URL}`
        return await HttpMethod.post(url, request)
    }

    static update = async (userID: number, request: WriteUser) => {
        const url = `${this.BASE_URL}/${userID}`
        return await HttpMethod.put(url, request)
    }

    static delete = async (userID: number) => {
        const url = `${this.BASE_URL}/${userID}`
        return await HttpMethod.delete(url)
    }
}