/* eslint-disable jsx-a11y/anchor-is-valid */
import { Button, Card, Empty, Input, Select, Space, Table } from 'antd';
import * as React from "react";
import { CloseOutlined, EditOutlined, EyeOutlined, FileAddOutlined, SearchOutlined, SyncOutlined } from '@ant-design/icons';
import { handleError } from '../../../utils/error';
import { Snackbar } from '../../../components/js/Snackbar';
import { useNavigate } from 'react-router-dom';
import { EventForm } from './EventForm';
import { showConfirmModal, showFormModal } from '../../../components/Modals';
import moment from 'moment';
import { EventDetails, EventFilter, GAME_TYPES, WriteEvent } from '../../../api/models/event';
import { EventService } from '../../../api/services/event';
import { RoundService } from '../../../api/services/round';
import { AuthService } from '../../../api/services/auth';
import { v4 } from 'uuid';

export const EventList = () => {
    const navigate = useNavigate()
    const unauthorizedCallback = () => {
        navigate('/login', { state: { unauthorized: true }})
    }

    const [loading, setLoading] = React.useState(true)
    const [openWsConnection, setOpenWsConnection] = React.useState(null)
    const wsConnections: WebSocket[] = []

    const [events, setEvents] = React.useState([])

    const [showSnackbar, setShowSnackbar] = React.useState(false)
    const [snackbarMesssge, setSnackbarMesssge] = React.useState(null)
    const [snackbarType, setSnackbarType] = React.useState('error')
    const [snackbarKey, setSnackbarKey] = React.useState(0)

    const [search, setSearch] = React.useState('')
    const [gameTypeFilter, setGameTypeFilter] = React.useState('')
    const [currentPageNumber, setCurrentPageNumber] = React.useState(1)
    const [total, setTotal] = React.useState(0)
    const defaultPageSize = 20

    const filter: EventFilter = {
        search: search,
        gameType: gameTypeFilter,
        pageSize: defaultPageSize,
        pageNumber: currentPageNumber,
        orderBy: '',
        orderDirection: ''
    }

    const columns = [
        {
            title: 'Arena',
            dataIndex: 'arena',
            key: 'arena',
            sorter: true
        },
        {
            title: 'Type',
            dataIndex: 'type',
            key: 'type',
            render: (data: any) => {
                return data || '---'
            },
            width: '20%'
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            sorter: true
        },
        {
            title: 'Game Type',
            dataIndex: 'gameType',
            key: 'gameType',
            sorter: true
        },
        {
            title: 'Start',
            dataIndex: 'startAt',
            key: 'startAt',
            render: (data: any) => {
                return data ? moment(data).format('llll') : '---'
            },
            sorter: true
        },
        {
            title: 'End',
            dataIndex: 'endAt',
            key: 'endAt',
            render: (data: any) => {
                return data ? moment(data).format('llll') : '---'
            },
            sorter: true
        },
        {
            title: 'Action',
            dataIndex: 'action',
            key: 'action',
            width: '15%'
        }
    ]

    const locale = {
        emptyText: <Empty />,
    };

    React.useEffect(() => {
        const wsID = v4()
        const wsConnection = `${process.env.REACT_APP_WS_BASE_URL}?id=${wsID}`
        console.log(`listening to ${wsConnection}`);
        // start ws listener
        let ws = new WebSocket(wsConnection);
        wsConnections.push(ws)
        ws.onopen = () => {
            console.log(`WebSocket connection opened`);
        };

        ws.onmessage = (message: any) => {
            const payload = JSON.parse(message.data)
            if (payload.updates === 'events') {
                getEvents()
            }
        }

        ws.onclose = () => {
            console.log('Connection closed')
            ws.close()
            // reopen connection after automatically close on idle for 60s
            setOpenWsConnection(Math.random())
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [openWsConnection])

    React.useEffect(() => {
        filter.search = search
        filter.gameType = gameTypeFilter
        getEvents()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [search, gameTypeFilter])

    const getEvents = async () => {
        try {
            setLoading(true)

            const response = await EventService.list(filter)
            const data = response?.data as EventDetails[]
            const events = data?.map((v: any, key: any) => ({
                ...v,
                key,
                arena: <a onClick={() => { deleteWsConnections(); navigate(`/dashboard/events/${v.id}`)}} title='View Event' rel="noreferrer" className='text-primary'>{v.arena}</a>,
                type: getType(v),
                action: <Space className='flex flex-col items-center mx-4'>
                    { (!isEventCompleted(v) && !AuthService.isController() && !AuthService.isObserver()) && <Space className='flex flex-col'>
                            <Button className="btn-secondary font-mulishMedium text-body5 w-6.5rem" icon={<CloseOutlined/>} size='small' onClick={() => closeEvent(v)} title='Close'>Close</Button>    
                            <Button className="btn-primary font-mulishMedium text-body5 w-6.5rem" icon={<EditOutlined/>} size='small' onClick={() => showForm('Edit Event', v)} title='Edit'>Edit</Button>
                        </Space>
                    }
                    <Button className="btn-default font-mulishMedium text-body5" icon={<EyeOutlined/>} size='small' onClick={() => { deleteWsConnections(); navigate(`/dashboard/events/${v.id}`)}} title='View'>View Results</Button>
                </Space>
            }))

            setEvents(events)
            setTotal(response.total)
        } catch (e) {
            setEvents([])
            setTotal(0)

            const errMsg = handleError(e, unauthorizedCallback)
            triggerSnackbar(errMsg, 'error')
        } finally {
            setLoading(false)
        }  
    }

    const isEventCompleted = (event: EventDetails) => {
        return event.endAt ? true : false
    }
    
    const showForm = (formTitle?: string, formValues?: object) => {
        showFormModal(
            <EventForm formTitle={formTitle || 'Add Event'} initialValues={formValues} onSuccessCallback={() => {
                triggerSnackbar('Event saved!', 'success')
                getEvents()
            }}
            unauthorizedCallback={unauthorizedCallback}
            />
        )
    }

    const getType = (event: EventDetails) => {
        return event.endAt ? 'COMPLETED' : event.status !== 'WAITING' ? 'IN-PROGRESS' : '---'
    }

    const closeEvent = async (event: EventDetails) => {
        const unCloseRound = await RoundService.getInProgress(event.id)
        if (unCloseRound) {
            triggerSnackbar(`Round ${unCloseRound.fightNumber} is still in progress. Please close all rounds!`, 'error')
            return
        }

        const close = async () => {
            const roundPromises = event.rounds?.filter((v: any) => v.status === 'WAITING').map((v: any) => deleteRound(v.id))
            Promise.all(roundPromises)

            const eventRequest = {
                status: 'CLOSED',
                endAt: moment().toDate()
            }
            await updateEvent(event.id, eventRequest as WriteEvent)
            getEvents()
        }

        showConfirmModal(`Are you sure you want to close ${event.arena}?`, () => { close() })
    }

    const updateEvent = async (eventID: number, request: WriteEvent) => {
        try {
            setLoading(true)
            await EventService.update(eventID, request)
        } catch (e) {
            const errMsg = handleError(e, unauthorizedCallback)
            triggerSnackbar(errMsg, 'error')
        } finally {
            setLoading(false)
        } 
    }

    const deleteRound = async (roundID: number) => {
        try {
            setLoading(true)
            await RoundService.delete(roundID)
        } catch (e) {
            const errMsg = handleError(e, unauthorizedCallback)
            triggerSnackbar(errMsg, 'error')
        } finally {
            setLoading(false)
        } 
    }

    const tableChange = async (pagination: any, f: unknown, sorter: any, e: any) => {
        const { current, pageSize } = pagination
        const { column, columnKey, order } = sorter
        const { action } = e

        filter.pageNumber = current || 1
        filter.pageSize = pageSize || defaultPageSize
        setCurrentPageNumber(filter.pageNumber)

        if (column) {
            filter.orderBy = columnKey
            filter.orderDirection = order === 'descend' ? 'DESC' : 'ASC'
        }

        if (action === 'sort' || action === 'search') {
            filter.pageNumber = 1
            setCurrentPageNumber(1)
        }

        await getEvents()
    }

    const triggerSnackbar = (message: string, type: string) => {
        setSnackbarMesssge(message)
        setSnackbarType(type)
        setSnackbarKey(Math.random())
        setShowSnackbar(true)
    }

    const deleteWsConnections = () => {
        wsConnections.forEach((v) => v.close())
    }

    return (
        <div className='flex flex-col justify-between my-8'>
            <Snackbar message={snackbarMesssge} show={showSnackbar} type={snackbarType} key={snackbarKey}/>
            <Card>
                <div className='lg:float-right flex flex-row-reverse mb-2 lg:w-3/4 flex-wrap md:!flex-nowrap'>
                    <div className='flex flex-row justify-between ml-2 mb-2'>
                        { (!AuthService.isController() && !AuthService.isObserver()) && <Button className="btn-primary ml-2 float-right" icon={<FileAddOutlined />} onClick={() => showForm(null, {})}>Add Event</Button> }
                        <Button className="btn-default ml-2" icon={<SyncOutlined/>} onClick={() => getEvents()}>Refresh</Button>
                    </div>
                    <div className='flex flex-row-reverse mb-2 flex-wrap md:!flex-nowrap w-full lg:!w-3/4'>
                        <Input
                            className='lg:w-3/4'
                            placeholder="Search by arena..."
                            suffix={<SearchOutlined style={{ color: 'rgba(0,0,0,.45)' }}/>}
                            allowClear
                            onChange={ (e) => {
                                setSearch(e.target.value)
                            }}
                        />
                        <Select placeholder='Filter by Game Type' style={{ width: 300 }}
                            className='w-full md:!w-2/5 mt-2 md:!mt-0 md:!mx-2'
                            allowClear
                            onChange={ (e) => {
                                setGameTypeFilter(e)
                            }}
                            options={GAME_TYPES.map((v) => ({ value: v, label: v}))}
                        />
                    </div>
                </div>
                <div className='mt-10'>
                    <Table 
                        onChange={tableChange}
                        loading={loading} 
                        columns={columns} 
                        dataSource={events} 
                        locale={locale}  
                        scroll={{ y: 10000, x: 500 }} 
                        pagination={ 
                            { 
                                className: 'mr-2',
                                position: ['bottomRight'],  
                                total,
                                showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
                                showSizeChanger: true,
                                defaultPageSize: defaultPageSize,
                                current: currentPageNumber
                            } 
                        }
                    />
                </div>
            </Card>
        </div>
    )
}