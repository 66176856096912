import * as React from 'react';
import { Navigate, Outlet } from "react-router-dom";
import { AuthService } from '../api/services/auth';

export const AuthGuard = () => {
  const validToken = AuthService.validateToken()  
  if (!validToken) {
    AuthService.logout()
  }

  return validToken ? <Outlet /> : <Navigate to="/login" state={{ unauthorized: true }}/>
}

export const TokenVerify = () => {
    const validToken = AuthService.validateToken()
    if (!validToken) {
      AuthService.logout()
    }
    
    return validToken ? <Navigate to="/" /> : <Outlet /> 
}

export const AdminGuard = () => {
  const validToken = AuthService.validateToken()  
  if (!validToken) {
    AuthService.logout()
  }

  return validToken ? 
    AuthService.isAdmin() ? <Outlet /> : <Navigate to="/dashboard"/> 
    : <Navigate to="/login" state={{ unauthorized: true }}/>
}