import * as React from "react";
import { Layout } from 'antd';
import { RouteContent } from '../../routes';
import { useLocation } from 'react-router-dom';
import { UserService } from '../../api/services/user';
import { Header } from '../../components/Header';

const { Content } = Layout;

const routeLabels = {
  'dashboard': 'Events',
  'event': 'Event -> Rounds'
}

export const Index = () => {
  const location = useLocation();

  const getRouteLabel = () => {
    if (location.pathname.includes('/events')) {
      return routeLabels['event']
    }

    if (location.pathname.includes('/dashboard')) {
      return routeLabels['dashboard']
    }

    return ''
  }
  

  const [user, setUser] = React.useState(null)

  React.useEffect(() => {
    async function getUser() {
      if (!UserService.user) {
        await UserService.get()
        setUser(UserService.user)
      }
    }

    getUser()
  }, [])

  if (!user) {
    return
  }

  return (
    <Layout>
      <Layout>
        <Header/>
        <Content className="px-8 bg-neutralBlack">
          <div className='flex justify-between mt-2'>
              <span className='text-header4 text-neutralWhite font-mulishBold'>{getRouteLabel()}</span>
          </div>
          <RouteContent />
        </Content>
      </Layout>
    </Layout>
  );
};