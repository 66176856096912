/* eslint-disable jsx-a11y/anchor-is-valid */
import { Button, Card, Empty, Modal, Popconfirm, Space, Switch, Table } from 'antd';
import * as React from "react";
import { CloseSquareFilled, DatabaseFilled, DeleteOutlined, FileAddOutlined, PhoneFilled, PlaySquareFilled, SyncOutlined } from '@ant-design/icons';
import { handleError } from '../../../utils/error';
import { Snackbar } from '../../../components/js/Snackbar';
import { useNavigate, useParams } from 'react-router-dom';
import moment from 'moment';
import { showConfirmModal, showFormModal } from '../../../components/Modals';
import { RoundForm } from './RoundForm';
import { RoundService } from '../../../api/services/round';
import { RoundDetails, RoundFilter } from '../../../api/models/round';
import { EventService } from '../../../api/services/event';
import { EventDetails, WriteEvent } from '../../../api/models/event';
import { UserService } from '../../../api/services/user';
import { AuthService } from '../../../api/services/auth';
import { v4 } from 'uuid';

export const RoundList = () => {
    const params = useParams();
    const { eventID } = params
    const user = UserService.user
    
    const navigate = useNavigate()
    const unauthorizedCallback = () => {
        navigate('/login', { state: { unauthorized: true }})
    }

    const resultLengthMap: any = {
        'INDIVIDUAL': 1,
        'DUAL': 2
    }

    const statusOrderMap: any = {
        'WAITING': 1,
        'OPEN': 2,
        'LAST CALL': 3,
        'CLOSED': 4
    }

    const [loading, setLoading] = React.useState(true)
    const [openWsConnection, setOpenWsConnection] = React.useState(null)
    const wsConnections: WebSocket[] = []

    const [rounds, setRounds] = React.useState([])
    const [event, setEvent] = React.useState(null)
    const [typeChecked, setTypeChecked] = React.useState(true)

    const [showSnackbar, setShowSnackbar] = React.useState(false)
    const [snackbarMesssge, setSnackbarMesssge] = React.useState(null)
    const [snackbarType, setSnackbarType] = React.useState('error')
    const [snackbarKey, setSnackbarKey] = React.useState(0)

    // const [search, setSearch] = React.useState('')
    const [total, setTotal] = React.useState(0)
    const [currentPageNumber, setCurrentPageNumber] = React.useState(1)
    const defaultPageSize = 20
    
    const filter: RoundFilter = {
        eventId: eventID,
        pageSize: defaultPageSize,
        pageNumber: currentPageNumber,
        orderBy: '',
        orderDirection: ''
    } 

    const columns = [
        {
            title: 'Round',
            dataIndex: 'fightNumber',
            key: 'fightNumber',
            width: '10%',
            sorter: true
        },
        {
            title: 'Action',
            dataIndex: 'action',
            key: 'action',
            width: '14%'
        },
        {
            title: 'Results',
            dataIndex: 'results',
            key: 'results',
            width: '15%'
        },
        {
            title: 'Start',
            dataIndex: 'startAt',
            key: 'startAt',
            sorter: true,
            width: '12%',
            render: (data: any) => {
                return data ? moment(data).format('llll') : '---'
            }
        },
        {
            title: 'End',
            dataIndex: 'endAt',
            key: 'endAt',
            sorter: true,
            width: '12%',
            render: (data: any) => {
                return data ? moment(data).format('llll') : '---'
            }
        }
    ]

    if (AuthService.isAdmin()) {
        columns.push({
            title: 'Updated By',
            dataIndex: 'updatedBy',
            key: 'updatedBy',
            width: '12%'
        })

        columns.push({
            title: 'Results Set By',
            dataIndex: 'resultsSetBy',
            key: 'resultsSetBy',
            width: '12%'
        })

        columns.push({
            title: 'Operation',
            dataIndex: 'operation',
            key: 'operation',
            width: '12%',
            sorter: false
        })
    }

    const locale = {
        emptyText: <Empty />,
    };

    React.useEffect(() => {
        const wsID = v4()
        const wsConnection = `${process.env.REACT_APP_WS_BASE_URL}?id=${wsID}`
        console.log(`listening to ${wsConnection}`);
        // start ws listener
        let ws = new WebSocket(wsConnection);
        wsConnections.push(ws)
        ws.onopen = () => {
            console.log(`WebSocket connection opened`);
        };

        ws.onmessage = (message) => {
            const payload = JSON.parse(message.data)
            if (payload.updates === 'events') {
                getEvent()
            }
        }

        ws.onclose = () => {
            console.log('Connection closed')
            ws.close()
            // reopen connection after automatically close on idle for 60s
            setOpenWsConnection(Math.random())
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [openWsConnection])

    React.useEffect(() => {
        getEvent()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    React.useEffect(() => {
        if (event) {
            filter.pageNumber = 1
            filter.orderBy = ''
            filter.orderDirection = ''

            getRounds()
            setTypeChecked(event.type === 'INDIVIDUAL')
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [event])

    const getEvent = async () => {
        try {
            setLoading(true)

            const response = await EventService.get(Number(eventID))
            setEvent(response as EventDetails)
        } catch (e) {
            const errMsg = handleError(e, unauthorizedCallback)
            triggerSnackbar(errMsg, 'error')
        } finally {
            setLoading(false)
        }  
    }

    const getRounds = async () => {
        try {
            setLoading(true)

            const response = await RoundService.list(filter)
            const data = response?.data as RoundDetails[]
            const rounds = data?.map((v: RoundDetails, key: any) => ({
                ...v,
                key,
                results: <Space>
                    <div className='flex flex-col justify-between items-center'>
                        <span>{v.status}</span>
                        <hr className='w-full my-4'/>
                        { getResult(v) }
                    </div>
                </Space>,
                action: <Space> { getAction(v) } </Space>,
                updatedBy: v.updatedBy?.username || '---',
                resultsSetBy: v.results?.map((r) => r.setBy.username).join(', ') || '---',
                operation: v.status !== 'WAITING' ? '---' : 
                    <Popconfirm
                        title={<span className='font-mulishBold text-body4'>Delete Round</span>}
                        description={<span className='font-mulishMedium text-body4'>Are you sure to delete round <span className='font-mulishBold'>{v.fightNumber}</span>?</span>}
                        okText="Yes"
                        cancelText="No"
                        okButtonProps={{ className: '!bg-primary font-mulishMedium text-body6'}}
                        cancelButtonProps={{ className: '!font-mulishMedium text-body6'}}
                        onConfirm={ async () => { 
                            await deleteRound(v.id); 
                            const eventRequest = { status: event.status }
                            await updateEvent(event.id, eventRequest as WriteEvent) 
                        }}
                    >
                        <Button className="btn-secondary text-neutralWhite" icon={<DeleteOutlined/>} title='Delete'/>
                    </Popconfirm>
            }))

            setRounds(rounds)
            setTotal(response.total)
        } catch (e) {
            setRounds([])
            setTotal(0)

            const errMsg = handleError(e, unauthorizedCallback)
            triggerSnackbar(errMsg, 'error')
        } finally {
            setLoading(false)
        }  
    }

    const getAction = (round: RoundDetails) => {
        switch(round.status.toUpperCase()) {
            case 'WAITING':
                return <Button className={`btn-default !bg-success-75 text-neutralWhite !border-0 ${AuthService.isObserver() && '!cursor-not-allowed'}`} icon={<PlaySquareFilled/>} onClick={() => updateStatus(round, 'OPEN')} title='Start'/>
            case 'OPEN':
                return <Button className={`btn-primary text-neutralWhite ${AuthService.isObserver() && '!cursor-not-allowed'}`} icon={<PhoneFilled/>} onClick={() => updateStatus(round, 'LAST CALL')} title='Last Call'/>   
            case 'LAST CALL':
                return <Button className={`btn-secondary text-neutralWhite ${AuthService.isObserver() && '!cursor-not-allowed'}`} icon={<CloseSquareFilled/>} onClick={() => updateStatus(round, 'CLOSED')} title='Close'/>
            case 'CLOSED':
                const resultsSubmitted = round.endAt !== null
                const userSubmitted = round.results?.find((v) => v.setBy.id === user.id)
                if (resultsSubmitted || userSubmitted) {
                    return <Button className="btn-default text-neutralBlack !cursor-not-allowed" icon={<DatabaseFilled/>} title='Closed'/>
                }

                return <Space>
                    { getResultButtons(round) }
                </Space>
        }
    }

    const getResultButtons = (round: RoundDetails) => {
        switch(round.event.gameType.toUpperCase()) {
            case 'COLOR':
                return <div className='flex flex-col justify-between items-center font-mulishMedium text-body5'>
                    <Button className={`${AuthService.isObserver() && '!cursor-not-allowed'} btn-secondary my-1 w-full`} onClick={() => updateResult(round, 'RED')}>Red</Button>
                    <Button className={`${AuthService.isObserver() && '!cursor-not-allowed'} btn-primary my-1 w-full`} onClick={() => updateResult(round, 'BLUE')}>Blue</Button>
                    <Button className={`${AuthService.isObserver() && '!cursor-not-allowed'} btn-default my-1 w-full !bg-warning !border-0`} onClick={() => updateResult(round, 'GOLD')}>Gold</Button>
                    <Button className={`${AuthService.isObserver() && '!cursor-not-allowed'} btn-default my-1 w-full !bg-success-25 !border-0`} onClick={() => updateResult(round, 'CANCELLED')}>Cancel</Button>
                </div>
            case 'APPLE':
                return <div className='flex flex-col justify-between items-center font-mulishMedium text-body5'>
                    <Button className={`${AuthService.isObserver() && '!cursor-not-allowed'} btn-secondary my-1 w-full`} onClick={() => updateResult(round, 'RED')}>Red</Button>
                    <Button className={`${AuthService.isObserver() && '!cursor-not-allowed'} btn-default my-1 w-full`} onClick={() => updateResult(round, 'WHITE')}>White</Button>
                    <Button className={`${AuthService.isObserver() && '!cursor-not-allowed'} btn-secondary my-1 w-full`} onClick={() => updateResult(round, 'REDX3')}>Red x 3</Button>
                    <Button className={`${AuthService.isObserver() && '!cursor-not-allowed'} btn-default my-1 w-full`} onClick={() => updateResult(round, 'WHITEX3')}>White x 3</Button>
                    <Button className={`${AuthService.isObserver() && '!cursor-not-allowed'} btn-default my-1 w-full !bg-success-25 !border-0`} onClick={() => updateResult(round, 'CANCELLED')}>Cancel</Button>
                </div>
            default:
                return <div className='flex flex-col justify-between items-center font-mulishMedium text-body5'>
                    <Button className={`${AuthService.isObserver() && '!cursor-not-allowed'} btn-secondary my-1 w-full`} onClick={() => updateResult(round, 'MERON')}>Meron</Button>
                    <Button className={`${AuthService.isObserver() && '!cursor-not-allowed'} btn-primary my-1 w-full`} onClick={() => updateResult(round, 'WALA')}>Wala</Button>
                    <Button className={`${AuthService.isObserver() && '!cursor-not-allowed'} btn-default my-1 w-full !bg-warning !border-0`} onClick={() => updateResult(round, 'DRAW')}>Draw</Button>
                    <Button className={`${AuthService.isObserver() && '!cursor-not-allowed'} btn-default my-1 w-full !bg-success-25 !border-0`} onClick={() => updateResult(round, 'CANCELLED')}>Cancel</Button>
                </div>
        }
    }

    const getResult = (round: RoundDetails) => {
        const results = round.results
        if (!results.length) {
            return ''
        }

        let latestResult = '---'
        if (event?.type === 'DUAL' && !round?.endAt) {
            latestResult = 'WAITING FROM OTHER USER'
        } else {
            const sortedResults = results.sort((a, b) => b.id - a.id)
            latestResult = sortedResults[0]?.result || latestResult
        }

        let className = ''
        switch (latestResult) {
            case 'MERON':
            case 'RED':
            case 'REDX3':
                className = 'tag-red'
                break
            case 'WALA':
            case 'BLUE':
                className = 'tag-blue'
                break
            case 'DRAW':
            case 'GOLD':
                className = 'tag-gold'
                break
            case 'WHITE':
            case 'WHITEX3':
                className = 'tag-white'
                break
        }

        return <span className={`${className} py-1 px-2 my-1 w-full rounded`}>{latestResult}</span>
    }

    const updateStatus = async (round: RoundDetails, status: string) => {
        if (AuthService.isObserver()) {
            return
        }

        const update = async () => {
            try {
                const roundByID = await getRound(round.id)
                if (statusOrderMap[roundByID.status] >= statusOrderMap[status]) {
                    triggerSnackbar(`Round status already updated to ${roundByID.status}!`, 'warning')
                    return
                }

                // const eventRequest = await getEvent(false)
                const eventRequest = {} as any

                if (status === 'OPEN') {
                    if (!event.startAt) {
                        eventRequest.startAt = moment().utc().toDate()
                    }
                }

                await updateRoundStatus(round.id, status)

                if (status === 'LAST CALL') {
                    const nextWaiting = await getNextWaitingRound()
                    if (!nextWaiting || nextWaiting?.fightNumber < round.fightNumber) {
                        await RoundService.create({
                            eventId: event.id,
                            status: 'WAITING',
                            updatedBy: user.id
                        })
                    }
                }

                eventRequest.status = status
                await updateEvent(event.id, eventRequest as WriteEvent)
            } catch(e) {}
        }

        // const roundByID = await getRound(round.id)
        const roundByID = round
        const latestStatus = roundByID.status
        if (statusOrderMap[latestStatus] > statusOrderMap[status] || latestStatus === status) {
            getRounds()
            return
        }

        if (status === 'OPEN') {
            const inProgressRound = await getInProgressRound()
            if (inProgressRound && inProgressRound.id !== round.id) {
                triggerSnackbar(`Round ${inProgressRound?.fightNumber} is still in progress!`, 'error')
                return
            }

            const inProgressEvent = await getInProgressEvent()
            if (inProgressEvent && inProgressEvent.id !== Number(eventID)) {
                const inProgressRound = await getInProgressRound(inProgressEvent?.id)
                triggerSnackbar(`Arena ${inProgressEvent?.arena} is still in progress${inProgressRound ? ` in round ${inProgressRound.fightNumber}`: ''}, please close all in progress event before starting a new one!`, 'error')
                return
            }

            await update()
            return
        }

        showConfirmModal(`Are you sure you want to set ${status === 'CLOSED' ? 'CLOSE' : status}?`, () => { update() })
    }

    const updateResult = async (round: RoundDetails, result: string) => {
        if (AuthService.isObserver()) {
            return
        }

        const update = async () => {
            try {
                // const roundByID = await getRound(round.id)
                let roundByID = round
                if (roundByID.results.length === resultLengthMap[event.type]) {
                    getRounds()
                    return
                }
    
                if (event.type === 'DUAL') {
                    roundByID = await getRound(round.id)
                    if (!roundByID.results.length) {
                        await addResult(round.id, result)
                        await updateData()
                        return
                    }

                    if (roundByID.results.length !== resultLengthMap['DUAL']) {
                        if (roundByID.results[0].result !== result) {
                            showDifferentResultModal(result, roundByID.results[0].result, async (e: string) => {
                                await addResult(round.id, e)
                                await updateData()
                            })
                            return
                        }
                    } else {
                        getRounds()
                        return
                    }
                }

                await addResult(round.id, result)
                await updateData()
            } catch (e) {}
        }

        const updateData = async () => {
            try {                
                // const eventRequest = await getEvent(false)
                const eventRequest = { status: 'WAITING' }
                await updateEvent(event.id, eventRequest as WriteEvent)
            } catch (e) {}
        }

        const getClass = () => {
            switch (result) {
                case 'MERON':
                case 'RED':
                case 'REDX3':
                    return 'btn-secondary'
                case 'WALA':
                case 'BLUE':
                    return 'btn-primary'
                case 'DRAW':
                case 'GOLD':
                    return 'btn-default !bg-warning !border-0'
                case 'CANCELLED':
                    return 'btn-default bg-success-25 !border-0'
                default:
                    return 'btn-default transparent-hover'
            }
        }

        showConfirmModal(`Are you sure you want to declare ${result === 'CANCELLED' ? 'CANCEL' : result}?`, () => { update() }, getClass())
    }

    const updateEvent = async (eventID: number, request: WriteEvent) => {
        try {
            setLoading(true)
            await EventService.update(eventID, request)
        } catch (e) {
            const errMsg = handleError(e, unauthorizedCallback)
            triggerSnackbar(errMsg, 'error')
        } finally {
            setLoading(false)
        }  
    }

    const getRound = async (roundID: number) => {
        try {
            setLoading(true)
            return await RoundService.get(roundID)
        } catch (e) {
            const errMsg = handleError(e, unauthorizedCallback)
            triggerSnackbar(errMsg, 'error')
        } finally {
            setLoading(false)
        }  
    }

    const getInProgressRound = async (event?: number) => {
        try {
            setLoading(true)
            return await RoundService.getInProgress(event || Number(eventID))
        } catch (e) {
            const errMsg = handleError(e, unauthorizedCallback)
            triggerSnackbar(errMsg, 'error')
        } finally {
            setLoading(false)
        }  
    }

    const getNextWaitingRound = async () => {
        try {
            setLoading(true)
            return await RoundService.getNextWaiting(Number(eventID))
        } catch (e) {
            const errMsg = handleError(e, unauthorizedCallback)
            triggerSnackbar(errMsg, 'error')
        } finally {
            setLoading(false)
        }  
    }

    const getInProgressEvent = async () => {
        try {
            setLoading(true)
            return await EventService.getInProgress(Number(eventID))
        } catch (e) {
            const errMsg = handleError(e, unauthorizedCallback)
            triggerSnackbar(errMsg, 'error')
        } finally {
            setLoading(false)
        }  
    }


    const updateRoundStatus = async (roundID: number, status: string) => {
        try {
            setLoading(true)
            await RoundService.updateStatus(roundID, { status, updatedBy: user.id })
        } catch (e) {
            const errMsg = handleError(e, unauthorizedCallback)
            triggerSnackbar(errMsg, 'error')

            const error = JSON.parse(e.message)
            if (error?.error_code !== 'PROVIDER_FAILED') {
                throw e
            }
        } finally {
            setLoading(false)
        }  
    }

    const deleteRound = async (roundID: number) => {
        try {
            setLoading(true)
            await RoundService.delete(roundID)
        } catch (e) {
            const errMsg = handleError(e, unauthorizedCallback)
            triggerSnackbar(errMsg, 'error')
        } finally {
            setLoading(false)
        }  
    }

    const addResult = async (roundId: number, result: string) => {
        try {
            setLoading(true)
            await RoundService.createResult({
                roundId,
                setBy: user.id,
                result
            })
        } catch (e) {
            const error = JSON.parse(e.message)
            if (error?.error_code === 'RESULTS_SUBMITTED') {
                showResultSubmittedModal()
                throw e
            }

            const errMsg = handleError(e, unauthorizedCallback)
            triggerSnackbar(errMsg, 'error')
            
            if (error?.error_code !== 'PROVIDER_FAILED') {
                throw e
            }
        } finally {
            setLoading(false)
        }
    }

    const showForm = async () => {
        const inProgressEvent = await getInProgressEvent()
        if (inProgressEvent && inProgressEvent.id !== Number(eventID)) {
            const inProgressRound = await getInProgressRound(inProgressEvent?.id)
            triggerSnackbar(`Arena ${inProgressEvent?.arena} is still in progress${inProgressRound ? ` in round ${inProgressRound.fightNumber}`: ''}, please close all in progress event before starting a new one!`, 'error')
            return
        }

        showFormModal(
            <RoundForm eventID={event.id} onSuccessCallback={async (error?: string) => {
                if (error) {
                    triggerSnackbar(error, 'error')
                } else {
                    triggerSnackbar('Round saved!', 'success')
                }
                const eventRequest = { status: 'WAITING' }
                await updateEvent(event.id, eventRequest as WriteEvent)
            }}
            unauthorizedCallback={unauthorizedCallback}
            />
        )
    }

    const showResultSubmittedModal = () => {
        Modal.confirm({
            content: <div className="flex flex-col align-center justify-around mb-6">
                        <span className="text-body2 text-neutralBlack font-mulishMedium">Results already submitted, page will refresh in {<Timer callback={() => { Modal.destroyAll(); getRounds() }}/>}</span>
                    </div>,
            cancelButtonProps: {
                className: '!hidden'
            },
            okButtonProps: {
                className: 'btn-primary text-body4 h-8'
            },
            okText: 'Refresh now',
            onOk() {
                getRounds()
            }
        })
    }

    const showDifferentResultModal = (selected: string, otherSelected: string, callback: Function) => {
        const getClass = (result: string) => {
            switch (result) {
                case 'MERON':
                case 'RED':
                case 'REDX3':
                    return 'btn-secondary'
                case 'WALA':
                case 'BLUE':
                    return 'btn-primary'
                case 'DRAW':
                case 'GOLD':
                    return 'btn-default !bg-warning !border-0'
                case 'CANCELLED':
                    return 'btn-default bg-success-25 !border-0'
                default:
                    return 'btn-default transparent-hover'
            }
        }

        return (
            Modal.confirm({
                content: <div className="flex flex-col align-center justify-around mb-6">
                            <span className="text-body2 text-neutralBlack font-mulishMedium">
                                Are you sure you want to select {selected === 'CANCELLED' ? 'CANCEL' : selected}? Because the other controller selected {otherSelected}
                            </span>
                        </div>,
                cancelButtonProps: {
                    className: `${getClass(otherSelected)} text-body4 h-8`
                },
                okButtonProps: {
                    className: `${getClass(selected)} text-body4 h-8`
                },
                okText: `Switch to ${selected === 'CANCELLED' ? 'CANCEL' : selected}`,
                onOk() {
                  callback(selected)
                },
                cancelText: `Keep ${otherSelected}`,
                onCancel() {
                  callback(otherSelected)
                }
            })
        )
    }

    const tableChange = async (pagination: any, f: unknown, sorter: any, e: any) => {
        const { current, pageSize } = pagination
        const { column, columnKey, order } = sorter
        const { action } = e

        filter.pageNumber = current || 1
        filter.pageSize = pageSize || defaultPageSize
        setCurrentPageNumber(filter.pageNumber)

        if (column) {
            filter.orderBy = columnKey
            filter.orderDirection = order === 'descend' ? 'DESC' : 'ASC'
        }

        if (action === 'sort' || action === 'search') {
            filter.pageNumber = 1
            setCurrentPageNumber(1)
        }

        await getRounds()
    }

    const triggerSnackbar = (message: string, type: string) => {
        setSnackbarMesssge(message)
        setSnackbarType(type)
        setSnackbarKey(Math.random())
        setShowSnackbar(true)
    }

    const deleteWsConnections = () => {
        wsConnections.forEach((v) => v.close())
    }

    const validVideoUrl = () => {
        try {
            new URL(event.videoLink);
            return true
        } catch (err) {
            return false
        }
    }

    return (
        <div className='flex flex-col justify-between my-8'>
            <Snackbar message={snackbarMesssge} show={showSnackbar} type={snackbarType} key={snackbarKey}/>
            <Card>
                <a onClick={() => { deleteWsConnections(); navigate(`/dashboard`)}} title='Back to Dashboard' rel="noreferrer" className='text-primary font-mulishLight text-body3 w-1/5'>Back to Dashboard</a>
                <div className='flex flex-row justify-between mt-4' key='event'>
                    <div className='flex flex-col w-2/3'>
                        <span className='font-mulishMedium'>Arena: <span className='font-mulishBold text-body2'>{event?.arena || 'N/A'}</span></span>
                        <span className='font-mulishMedium my-2'>No. of Rounds: <span className='font-mulishBold text-body2'>{event?.numberOfRounds || 'N/A'}</span></span>
                        <span className='font-mulishMedium'>Status: <span className='font-mulishBold text-body3'>{event?.status || 'N/A'}</span></span>
                        <span className='font-mulishMedium my-2'>Game Type: <span className='font-mulishBold text-body3'>{event?.gameType || 'N/A'}</span></span>
                    </div>
                    <div className='float-right flex flex-col justify-start items-end flex-wrap'>
                        { (!event?.endAt && !AuthService.isController() && !AuthService.isObserver()) &&
                            [
                                <Switch key='switch' className='font-mulishMedium my-1' style={{ backgroundColor: '#4096ff' }} checkedChildren="Individual" unCheckedChildren="Dual"
                                    checked={typeChecked} 
                                    onChange={async (e) => {
                                        const type = e ? 'INDIVIDUAL' : 'DUAL'
                                        const eventRequest = { type }
                                        await updateEvent(event.id, eventRequest as WriteEvent)
                                    }}
                                />
                            ]
                        }
                        { (!event?.endAt && !AuthService.isObserver()) && <Button className="btn-primary my-1" icon={<FileAddOutlined />} onClick={async () => await showForm()}>Set Round</Button> }
                        <Button className="btn-default my-1 w-24" icon={<SyncOutlined/>} onClick={() => getEvent()}>Refresh</Button>
                    </div>
                </div>
                <div className={`${!event?.endAt ? 'lg:flex' : ''} mt-2 flex-row flex-wrap justify-center`} key='rounds'>
                    {
                        !event?.endAt &&
                        <div className='text-center lg:w-1/2 mb-6'>
                            {
                                !validVideoUrl() ? <span className='text-center font-mulishLight text-secondary'>Invalid video URL</span> :
                                <iframe
                                    src={event?.videoLink}
                                    title="Stream"
                                    allowFullScreen
                                    className='stream-iframe'
                                    width="100%" 
                                    style={{aspectRatio: '16/9', minHeight: '340px' }} 
                                    allow="autoplay"  
                                    >
                                </iframe>
                            }
                        </div>
                    }
                    <div className={`${!event?.endAt ? 'lg:w-1/2' : 'w-full'}`}>
                        <Table 
                            onChange={tableChange}
                            loading={loading} 
                            columns={columns} 
                            dataSource={rounds} 
                            locale={locale}  
                            scroll={{ y: 480, x: 700 }} 
                            pagination={ 
                                { 
                                    className: 'mr-2',
                                    position: ['bottomRight'],  
                                    total,
                                    showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
                                    showSizeChanger: true,
                                    defaultPageSize: defaultPageSize,
                                    current: currentPageNumber
                                } 
                            }
                        />
                    </div>
                </div>
            </Card>
        </div>
    )
}

const Timer = ({ callback }: { callback: Function }) => {
    const [delay, setDelay] = React.useState(5);
    const seconds = Math.floor(delay % 60);
   
    React.useEffect(() => {
      const timer = setInterval(() => {
        setDelay(delay - 1);
      }, 1000);
  
      if (delay === 0) {
        clearInterval(timer);
        callback()
      }
  
      return () => {
        clearInterval(timer);
      };
    });
  
    return (
      <>
        <span>{seconds}</span>
      </>
    );
};